// close icon on input
.ant-input-clear-icon {
  > span.anticon {
    transform: translateY(-3px) !important;
  }
}

// alway show clear icon on select component
.ant-select .ant-select-clear {
  opacity: 1 !important;
}
