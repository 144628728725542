:root {
  --primary-color: #32c790;
  --primary-color-dark: #09a76d;
  --primary-color-light: #70ebbe;
}

.c-table {
  // .ant-table-thead {
  //   th {
  //     font-weight: bold;
  //   }
  // }

  &.header-center {
    .ant-table-thead {
      th {
        text-align: center;
      }
    }
  }
}
